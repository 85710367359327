import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounceEffect from '../../hooks/useDebounceEffect'
import { useDispatch, useSelector } from 'react-redux'
import { setEsportes } from '../../appActions'

import NavExpanded from '../navExpanded';
import CheckBox from '../checkbox';
import Switcher from '../switcher';

const SportsPage = () => {
    const { t } = useTranslation()
    const esportesEnum = useSelector(state => state.filters.esportesEnum)
    const dispatch = useDispatch()

    const filtersArray = [
        {
            code: 'BASEBALL',
            label: 'Baseball',
        },
        {
            code: 'BASKET',
            label: 'Basket',
        },
        {
            code: 'CRICKET',
            label: 'Cricket',
        },
        {
            code: 'E_SPORTS',
            label: 'E-Sports',
        },
        {
            code: 'FUTEBOL',
            label: 'Futebol',
        },
        {
            code: 'HANDBALL',
            label: 'Handball',
        },
        {
            code: 'HOCKEI',
            label: 'Hockei',
        },
        {
            code: 'MMA',
            label: 'MMA',
        },
        {
            code: 'RUGBY_LEAGUE',
            label: 'Rugby League',
        },
        {
            code: 'TENIS',
            label: 'Tenis',
        },
        {
            code: 'TENIS_MESA',
            label: 'Tenis Mesa',
        },
        {
            code: 'VOLEI',
            label: 'Volei',
        }
    ]

    const getDefaultFiltersValue = () => {
        if (esportesEnum.length === 0)
            return []
        if (esportesEnum[0] === "ALL") {
            return filtersArray
        }
        return filtersArray.filter(f => esportesEnum.includes(f.code))
    }

    const [isAllSelect, setIsAllSelect] = useState(false)
    const [filtersSelected, setfiltersSelected] = useState(getDefaultFiltersValue())

    useEffect(() => {
        setIsAllSelect(filtersSelected.length === filtersArray.length)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersSelected])

    useDebounceEffect(() => {
        dispatch(setEsportes(getEsportesNames()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersSelected], 700)

    const handleCheck = (option, value) => {
        if (value) {
            setfiltersSelected([...filtersSelected, option])
        } else {
            setfiltersSelected(filtersSelected.filter(s => s.code !== option.code))
        }
    }

    const toggleSelectAll = (value) => {
        if (value) {
            setfiltersSelected(filtersArray)
            setIsAllSelect(true)
        } else {
            setfiltersSelected([])
            setIsAllSelect(false)
        }
    }

    const getEsportesNames = () => {
        return filtersSelected.map(f => f.code)
    }

    const contains = (filter) => {
        return filtersSelected.find(selected => selected.code === filter.code) || false
    }

    return (
        <NavExpanded>
            <span className="subtitle">{t('sports')}</span>
            <Switcher label={t('checkAll')} value={isAllSelect} onChange={value => toggleSelectAll(value)} />
            {
                filtersArray.map((filter, index) => <CheckBox key={index} value={contains(filter)} label={filter.label} onChange={value => handleCheck(filter, value)} />)
            }
        </NavExpanded>
    )
}

export default SportsPage;