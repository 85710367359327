import { useEffect, useRef } from 'react';

const useDebounceEffect = (func, deps, time = 500) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) {
            const timer = setTimeout(() => {
                func();
            }, time);

            return () => clearTimeout(timer);
        }
        else didMount.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

export default useDebounceEffect;
