import React from 'react';
import { connect } from 'react-redux'

import DashboardLayout from '../components/Layout';
import SportsNav from '../components/nav-sports'
import PercentNav from '../components/nav-percent'
import BookmakerNav from '../components/nav-bookmaker'
import SortNav from '../components/nav-sort'
import ZoomNav from '../components/nav-zoom'

const getComponent = (currentPage) => {
    switch (currentPage) {
        case '/bookmakers':
            return <BookmakerNav />

        case '/percent':
            return <PercentNav />

        case '/projects':
            return <SortNav />

        case '/sports':
            return <SportsNav />

        case '/zoom':
            return <ZoomNav />
        default:
            return null;
    }
}

const Home = ({ currentPage }) => {
    return (
        <DashboardLayout>
            {getComponent(currentPage)}
        </DashboardLayout>
    )
}

const HomeConnect = connect(store => store)(Home)
export default HomeConnect;