import { format, formatDistance, parseISO, subSeconds } from 'date-fns'

export const formatDate = (date) => {
    const parsed = parseISO(date.split('.').reverse().join('-'));
    return format(parsed, "dd MMM")
}

export const getAge = (age_1, age_2) => {
    let correctSeconds = Math.min(age_1, age_2)

    const currentDate = Date.now()
    const lastUpdate = subSeconds(currentDate, correctSeconds)

    const timeDistance = formatDistance(lastUpdate, currentDate);
    return timeDistance
}
