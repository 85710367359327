import api from './api'
import faker from 'faker'

export const getCurrentSession = async () => {
    const session = await JSON.parse(localStorage.getItem('session'))
    return session
}

export const isLogged = async () => {
    return await localStorage.getItem('isLogged') || false
}

const generateAuthorizationHeader = () => {
    const username = 'bethacker'
    const password = '1234'

    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

    return token
}

export const login = async ({ username, password, hash = faker.random.uuid(), grant_type = 'password' }) => {
    let bodyFormData = new FormData();
    bodyFormData.append('username', username)
    bodyFormData.append('password', password)
    bodyFormData.append('hash', hash)
    bodyFormData.append('grant_type', grant_type)

    const headers = { 'Authorization': `Basic ${generateAuthorizationHeader()}` }
    const { data: response } = await api.post('/oauth/token', bodyFormData, { headers })
    await localStorage.setItem("hash", hash)
    await localStorage.setItem("isLogged", true)
    await localStorage.setItem("session", JSON.stringify(response))
}


export const logout = async () => {
    await api.delete('/usuarios/revoke')
    localStorage.removeItem("session")
    localStorage.removeItem('isLogged')
    localStorage.removeItem('hash')
    window.location.href = '/#/login'
}

export const register = async (userData) => {
    const { data: response } = await api.post('/clientes', userData)
    return response
}

export const validate = async (token) => {
    await api.put('/usuarios/autenticacao', [token])
}

export const checkUser = async () => {
    const hash = await localStorage.getItem("hash")
    let bodyFormData = new FormData();
    bodyFormData.append('hash', hash)

    return await api.post('/usuarios/check', bodyFormData)
}

export const forgotPassService = async (email) => {
    let bodyFormData = new FormData();
    bodyFormData.append('email', email)
    await api.post('/usuarios/esqueceu_senha', bodyFormData)
}

export const updatePass = async ({ token, novaSenha }) => {
    let bodyFormData = new FormData();
    bodyFormData.append('token', token)
    bodyFormData.append('novaSenha', novaSenha)
    await api.put('/usuarios/atualizar_senha', bodyFormData)
}

export const changePassword = async ({ senhaAtual, novaSenha }) => {
    let bodyFormData = new FormData();
    bodyFormData.append('senhaAtual', senhaAtual)
    bodyFormData.append('novaSenha', novaSenha)
    await api.put('/usuarios/newsenha', bodyFormData)
}