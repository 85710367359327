import api from './api'

export const updateUser = async (data) => {
    const response = await api.put('/clientes', data)
    return response
}

export const doReport = async (value) => {
    const response = await api.post('/clientes/report', { ...value })
    return response
}

export const updateImage = async (file) => {
    let formData = new FormData()
    formData.append('arquivo', file)
    const response = await api.post('/clientes/image', formData)
    return response
}

export const removeImage = async () => {
    const response = await api.delete('/clientes/image')
    return response
}

export const getUserInfo = async () => {
    const response = await api.get('/clientes/dados')
    return response.data
}

export const getImage = (imageName) => {
    if (!imageName) {
        return ''
    }
    return `${process.env.REACT_APP_API_BASE_URL}/clientes/image/${imageName}`
}