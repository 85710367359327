import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast, ToastContainer } from 'react-toastify';

import Input from "../components/input";
import Button from "../components/button";
import { useParams, useHistory } from "react-router-dom"
import BetHackerIcon from '../assets/BetHackerLogo.png'

import { updatePass } from '../service/authService'
import sleep from '../utils/sleep'

const ResetPassword = () => {
    const { t } = useTranslation()

    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const { token } = useParams()
    const history = useHistory()

    const handleChangePassword = async () => {
        if (!newPassword || !confirmNewPassword) {
            toast.warning(t('fillAllFields'))
            return
        }

        if (newPassword !== confirmNewPassword) {
            toast.error(t('passDontMatch'))
            return
        }

        try {
            await updatePass({ token, novaSenha: newPassword })
            toast.success(t('passChangedSuccess'))
            await sleep(2000)
            history.push('/login')
        } catch (err) {
            toast.error(err.message)
        }
    }

    return (
        <div className="reset-pass-container">
            <ToastContainer />
            <img src={BetHackerIcon} alt="bet hacker icon" />
            <label>{t('newPassword')}</label>
            <Input type="password" accent value={newPassword} onChange={setNewPassword} />

            <label>{t('confirmNewPass')}</label>
            <Input type="password" accent value={confirmNewPassword} onChange={setConfirmNewPassword} />
            <Button title="Save password" onClick={() => handleChangePassword()} />
        </div>
    )
}

export default ResetPassword