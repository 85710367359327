import React, { useState, useEffect } from 'react'

import { useParams, useHistory } from "react-router-dom"
import { validate } from "../service/authService"
import BetHackerIcon from '../assets/BetHackerLogo.png'

import sleep from '../utils/sleep'
import { useTranslation } from 'react-i18next'

const ResetPassword = () => {
    const { t } = useTranslation()

    const { token } = useParams()
    const history = useHistory()
    const [message, setMessage] = useState('')

    useEffect(() => {
        const confirm = async () => {
            try {
                setMessage(t('validatingUser'))
                await validate(token)
                setMessage(t('userValidateSuccess'))
                await sleep(1000)
                setMessage(t('redirectingLogin'))
                history.push('/login')
            } catch (err) {
                setMessage(`Error: ${t('validationUserError')}`)
            }
        }

        confirm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="confirm-user-container">
            <img src={BetHackerIcon} alt="bet hacker icon" />
            <span>{message}</span>
        </div>
    )
}

export default ResetPassword