import React, { useEffect, useState } from 'react'
import useDidMountEffect from '../../hooks/useDidMountEffect'

import './style.css'

import { formatDate, getAge } from "../../utils/date";
import Button from "../button"
import Input from "../input"
import CloseIcon from '@material-ui/icons/Close';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import { getIconBySportName } from '../../utils/sportIcon'

const Calculator = ({ match, onBack }) => {
    const { t } = useTranslation()

    const [currency, setCurrency] = useState('BRL')

    const [receiptA, setReceiptA] = useState(0)
    const [receiptB, setReceiptB] = useState(0)

    const [participationA, setParticipationA] = useState(match.odd_a.porcentagemOdd)
    const [participationB, setParticipationB] = useState(match.odd_b.porcentagemOdd)
    const [total, setTotal] = useState(100)


    const [val1, setVal1] = useState(match.odd_a.value)
    const [val2, setVal2] = useState(match.odd_b.value)

    const [oddPercent, setOddPercent] = useState(match.odd_a.porcentagem)

    useDidMountEffect(() => {
        if (!val1 || !val2 || !total) {
            return
        }
        if (val1 === '0' && val2 === '0') {
            setOddPercent(match.odd_a.porcentagem)
            return
        }
        const percentX = parseFloat(val1) / (parseFloat(val1) + parseFloat(val2))
        const percentY = parseFloat(val2) / (parseFloat(val1) + parseFloat(val2))

        const z = percentX * total
        const w = percentY * total

        const rx = val2 * z - total
        const ry = val1 * w - total

        setOddPercent((100 * rx / total))
        setReceiptA(rx)
        setReceiptB(ry)
        setParticipationA(w)
        setParticipationB(z)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val1, val2, total])


    const resetValues = () => {

        setCurrency('BRL')

        setReceiptA(0)
        setReceiptB(0)

        setParticipationA(match.odd_a.porcentagemOdd)
        setParticipationB(match.odd_b.porcentagemOdd)
        setTotal(100)


        setVal1(match.odd_a.value)
        setVal2(match.odd_b.value)

        setOddPercent(match.odd_a.porcentagem)
    }


    useEffect(() => {
        resetValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match])

    return (
        <div className="calculator-page-container">
            <div className="row">
                <Button className="back-button" title={t('back')} onClick={onBack} />
            </div>

            <div className="cards-container">
                <div className="info-container">
                    <div className="row info">
                        <div className="percent col justify-center col-2">
                            <span className="font-lg">{oddPercent.toFixed(2)} %</span>
                        </div>

                        <div className="col col-2">
                            <div className="row justify-center">
                                <div className="col align-center justify-center">
                                    {getIconBySportName(match.esporte)}
                                    <span className="font-md text-center">{match.esporte}</span>
                                </div>
                            </div>
                            <div className="row align-center">
                                <span className="text-center text-ellipses">{getAge(match.odd_a.age, match.odd_b.age)}</span>
                            </div>
                        </div>
                        <div className="col col-2">
                            <div className="row bordered align-center color-green">
                                <span className="text-ellipses">{match.odd_a.nameBookmaker} </span>
                            </div>
                            <div className="row align-center color-green">
                                <span className="text-ellipses">{match.odd_b.nameBookmaker} </span>
                            </div>
                        </div>

                        <div className="col col-4">
                            <div className="row bordered">
                                <div className="row my-auto">
                                    <div className="col align-center justify-center ">
                                        <span className="text-center bold" >{match.localteam}</span>
                                        <span className="date text-center">{formatDate(match.date)}</span>
                                        <span className="time font-sm text-center">{match.time}</span>
                                    </div>
                                    <div className="row align-center" style={{ width: '40px' }}>
                                        <div className="text-ellipses" >{match.odd_a.type} - {match.odd_a.pontos ? `${match.odd_a.name}: ${match.odd_a.pontos}` : match.odd_a.name}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="row  my-auto">
                                    <div className="col align-center">
                                        <span className="text-center bold">{match.awayteam}</span>
                                        <span className="date text-center">{formatDate(match.date)}</span>
                                        <span className="time font-sm text-center">{match.time}</span>
                                    </div>
                                    <div className="row align-center" style={{ width: '40px' }}>
                                        <div className="text-ellipses" >{match.odd_b.type} - {match.odd_b.pontos ? `${match.odd_b.name}: ${match.odd_b.pontos}` : match.odd_b.name}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col col-2">
                            <div className="row bordered align-center color-green">
                                <span>{match.odd_a.value} </span>
                            </div>
                            <div className="row align-center color-green">
                                <span>{match.odd_b.value} </span>
                            </div>
                        </div>
                        <div className="tail"></div>
                    </div>
                </div >

                <div className="calculator">
                    <div className="row">
                        <div className="col-3">
                            <div className="text-ellipses">{match.odd_a.type}</div>
                        </div>
                        <div className="col-content">
                            <CloseIcon fontSize="small" />
                        </div>
                        <div className="col-2 text-center text-ellipses">
                            <a className="color-green" href={match.odd_a.link}>
                                {match.odd_a.nameBookmaker}
                            </a>
                        </div>
                        <div className="col-content">
                            <AutorenewIcon fontSize="small" />
                        </div>
                        <div className="col-2">
                            <Input className="calculator-input" type="number" white value={val1} onChange={setVal1} />
                        </div>
                        <div className="col-1">
                            <span>{participationA.toFixed(2)}</span>
                        </div>
                        <div className="col-2">
                            <span>{`${match.odd_a.value} ${currency}`}</span>
                        </div>
                        <div className="col-1">
                            <span>{receiptA.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-3'>
                            <div className='text-ellipses'>{match.odd_b.type}</div>
                        </div>
                        <div className="col-content">
                            <CloseIcon fontSize="small" />
                        </div>
                        <div className="col-2 text-center text-ellipses">
                            <a className="color-green" href={match.odd_b.link}>
                                {match.odd_b.nameBookmaker}
                            </a>
                        </div>
                        <div className="col-content">
                            <AutorenewIcon fontSize="small" />
                        </div>
                        <div className="col-2">
                            <Input className="calculator-input" type="number" white value={val2} onChange={setVal2} />
                        </div>
                        <div className="col-1">
                            <span> {participationB.toFixed(2)} </span>
                        </div>
                        <div className="col-2">
                            <span className="">{`${match.odd_b.value} ${currency}`}</span>
                        </div>
                        <div className="col-1">
                            <span>{receiptB.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Button className="refresh-button" onClick={resetValues} title={t('refresh')} type="secondary" />
                        </div>
                        <div className="col-3">
                            <Input className="calculator-input-money" type="number" white value={total} onChange={setTotal} />
                        </div>
                        <div className="col-3">
                            <Select
                                className="td-currency"
                                value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                <MenuItem className="currency-menu-item" value="BRL">BRL</MenuItem>
                                <MenuItem className="currency-menu-item" value="USD">USD</MenuItem>
                                <MenuItem className="currency-menu-item" value="EUR">EUR</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Calculator