import React from 'react';

const BodyWrapper = ({ children }) => {
  return (
    <div className="relative min-h-screen" style={{ height: '100%' }}>
      <main className="w-full min-h-screen" style={{ height: '100%' }}>{children}</main>
    </div>
  );
};

export default BodyWrapper;
