import React, { useEffect, useState } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";

import Home from "./Home";
import SignIn from "./SignIn";
import SettingsPage from "./Settings";
import ResetPassword from './ResetPassword';
import ConfirmUser from './ConfirmUser';
import LandingPage from "./LandingPage";
import { isLogged } from "../service/authService";

const Routes = () => {

  const [authenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    async function checkLogged() {
      const log = await isLogged()
      setAuthenticated(log)
    }
    checkLogged()
  }, [])

  return (
    <HashRouter >
      <Switch>
        <Route exact path="/" component={authenticated ? () => <Home /> : () => <LandingPage />} />

        <Route exact path="/login" component={SignIn} />

        <Route exact path="/settings" component={SettingsPage} />

        <Route exact path="/auth/confirm/:token" component={ConfirmUser} />

        <Route exact path="/auth/reset-pass/:token" component={ResetPassword} />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
