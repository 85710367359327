import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useDebounceEffect from '../../hooks/useDebounceEffect';

import { setMarcados, setSuperMarcados } from '../../appActions';

import CheckBox from '../checkbox';
import Switcher from '../switcher';
import NavExpanded from '../navExpanded';

const BookmakersPage = () => {
    const { t } = useTranslation()
    const filters = useSelector(state => state.filters)
    const dispatch = useDispatch()

    const filtersArray = [
        {
            label: 'SBO',
            code: 'SBO'
        },
        {
            label: 'Williamhill',
            code: 'WILLIAMHILL'
        },
        {
            label: 'Sportingbet',
            code: 'SPORTINGBET'
        },
        {
            label: 'PNCL',
            code: 'PNCL'
        },
        {
            label: 'Ladbrokes',
            code: 'LADBROKES'
        },
        {
            label: 'BWIN',
            code: 'BWIN'
        },
        {
            label: 'Expekt',
            code: 'EXPEKT'
        },
        {
            label: 'Betway',
            code: 'BETWAY'
        },
        {
            label: '188bet',
            code: '188BET'
        },
        {
            label: 'Marathon',
            code: 'MARATHON'
        },
        {
            label: 'Betsson',
            code: 'BETSSON'
        },
        {
            label: '1xbet',
            code: '1XBET'
        },
        {
            label: 'Bet365',
            code: 'BET365'
        },
        {
            label: 'Dafabet',
            code: 'DAFABET'
        },
        {
            label: 'Betcris',
            code: 'BETCRIS'
        },
        {
            label: 'Bovada',
            code: 'BOVADA'
        },
        {
            label: 'Unibet',
            code: 'UNIBET'
        },
        {
            label: '888sport',
            code: '888SPORT'
        },
        {
            label: 'Tipico',
            code: 'TIPICO'
        },
        {
            label: '10bet',
            code: '10BET'
        }
    ]

    const getDefaultFiltersValue = (type) => {
        if (filters[type].length === 0)
            return []
        if (filters[type][0] === "ALL") {
            return filtersArray
        }
        return filtersArray.filter(f => filters[type].includes(f.code))
    }

    const [isAllSelect, setIsAllSelect] = useState(false)
    const [marked, setMarked] = useState(getDefaultFiltersValue('marcados'))
    const [supermarked, setSupermarked] = useState(getDefaultFiltersValue('superMarcados'))

    useEffect(() => {
        setIsAllSelect(marked.length === filtersArray.length)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marked])

    useDebounceEffect(() => {
        dispatch(setMarcados(getBookMarkersNames('MARCADOS')))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marked], 1000)

    useDebounceEffect(() => {
        dispatch(setSuperMarcados(getBookMarkersNames('SUPERMARCADOS')))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supermarked], 1000)

    const handleCheckMarked = (option, value) => {
        if (value) {
            setMarked([...marked, option])
        } else {
            setMarked(marked.filter(s => s.code !== option.code))
        }
    }

    const handleCheckSuperMarked = (option) => {
        if (supermarked.find(s => s.code === option.code))
            setSupermarked(supermarked.filter(s => s.code !== option.code))
        else
            setSupermarked([...supermarked, option])
    }

    const toggleSelectAll = (value) => {
        if (value) {
            setMarked(filtersArray)
        } else {
            setMarked([])
        }
    }

    const getBookMarkersNames = (type) => {
        switch (type) {
            case 'MARCADOS':
                if (marked.length === filtersArray.length)
                    return ['ALL']
                return marked.map(f => f.code)
            case 'SUPERMARCADOS':
                if (supermarked.length === filtersArray.length)
                    return ['ALL']
                return supermarked.map(f => f.code)
            default:
                return []
        }
    }

    const contains = (filter) => {
        return marked.find(selected => selected.code === filter.code) || false
    }

    return (
        <NavExpanded>
            <span className="subtitle">{t('bookMakers')}</span>
            <Switcher label={t('checkAll')} value={isAllSelect} onChange={value => toggleSelectAll(value)} />
            {
                filtersArray.map((filter, index) => <CheckBox changeBackground key={index} value={contains(filter)} label={filter.label} onSelect={() => handleCheckSuperMarked(filter)} onChange={value => handleCheckMarked(filter, value)} />)
            }
        </NavExpanded>
    )
};

export default BookmakersPage;
