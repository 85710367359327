import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { setValores } from '../../appActions';
import useDebounceEffect from '../../hooks/useDebounceEffect';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import NavExpanded from '../navExpanded';

import './style.css'

const PercentPage = () => {
    const { t } = useTranslation()
    const filters = useSelector(state => state.filters)
    const dispatch = useDispatch()

    const getDefaultValores = (pos) => {
        if (filters.valores.length === 0)
            return ''
        if (filters.valores[pos] === -1)
            return ''

        return filters.valores[pos]
    }

    const [valorInicial, setValorInicial] = useState(getDefaultValores(0))
    const [valorFinal, setValorFinal] = useState(getDefaultValores(1))

    useDebounceEffect(() => {
        if ((valorFinal && valorFinal) && (valorFinal < valorInicial)) {
            toast.warning(t('minMaxAlert'))
        } else {
            dispatch(setValores([parseFloat(valorInicial || -1.0), parseFloat(valorFinal || -1.0)]))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valorInicial, valorFinal])


    return (
        <NavExpanded>
            <ToastContainer />
            <div className="percent">
                <span className="percent-title">{t('percent')}</span>

                <div className="inputs">
                    <input type="number" value={valorInicial} className="input-min" placeholder="Min." onChange={e => setValorInicial(e.target.value)} />
                    <hr />
                    <input type="number" value={valorFinal} className="input-max" placeholder="Max." onChange={e => setValorFinal(e.target.value)} />
                </div>
            </div>
        </NavExpanded>
    )
}

export default PercentPage;