import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { setCurrentPage } from '../appActions'
import { connect } from 'react-redux'

import BetHackerIcon from '../assets/BetHackerLogo.png'
import { ReactComponent as Zoom } from '../assets/zoom-in.svg';
import { ReactComponent as Percentage } from '../assets/percentage.svg';
import { ReactComponent as Bookmarks } from '../assets/sport-list.svg';
import { ReactComponent as Arrows } from '../assets/arrows-sort.svg';
import { ReactComponent as Sports } from '../assets/ball-basketball.svg';
import { ReactComponent as Settings } from '../assets/settings.svg';


const NavSidebar = ({ dispatch, currentPage }) => {
  const history = useHistory()
  const location = useLocation()

  const items = [
    {
      render: () => <Zoom className="svg" />,
      id: "/zoom",
    },
    {
      render: () => <Arrows className="svg" />,
      id: "/projects",
    },
    {
      render: () => <Percentage className="svg" />,
      id: "/percent",
    },
    {
      render: () => <Bookmarks className="svg" />,
      id: "/bookmakers",
    },
    {
      render: () => <Sports className="svg" />,
      id: "/sports",
    },
  ]

  useEffect(() => {
    if (location.pathname === '/')
      return
    dispatch(setCurrentPage(location.pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const changeSelected = (pageId) => {
    if (location.pathname !== '/') {
      history.push('/')
    }
    dispatch(setCurrentPage(pageId))
  }

  const getClasses = (pageId) => {
    if (currentPage === pageId) {
      return 'nav-sidebar__item--selected'
    }
    return ''
  }

  const handleRedirect = (path) => {
    if (location.pathname === path)
      return

    history.push(path)
    dispatch(setCurrentPage(path))
  }

  return (
    <div className="nav-sidbar">
      <div className="nav-header">
        <img src={BetHackerIcon} alt="bet hacker icon" onClick={() => changeSelected('/')} />
      </div>

      <div className="nav-filters">

        {
          items.map((item, index) => (
            <div key={index} onClick={() => changeSelected(item.id)} className={`nav-sidebar__item ${getClasses(item.id)}`}>
              { item.render()}
            </div>
          ))
        }
      </div>

      <div onClick={() => handleRedirect('/settings')} className="nav-config">
        <div className={`nav-sidebar__item ${getClasses('/settings')}`}>
          <Settings className="svg" />
        </div>
      </div>
    </div>
  );
};

const NavSidebarConnect = connect(store => store)(NavSidebar)
export default NavSidebarConnect;