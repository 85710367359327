import React from "react";

import NavSidebar from "./NavSidebar";
import BodyWrapper from "./BodyWrapper";

import Grid from '../components/grid';
import BetHeader from '../components/bet-header';

const DashboardLayout = ({ children, showGrid = true }) => {
  return (
    <BodyWrapper>
      <div className="flex min-h-screen" style={{ backgroundColor: '#eee', height: '100%' }}>
        <NavSidebar />

        <div className="flex flex-col flex-1 overflow-hidden">
          <main className="content" style={{ height: '100%' }}>
            <section className="sm:flex-row flex flex-col flex-1" style={{ height: '100%' }}>
              <div
                className="content-box"
                style={{ flexGrow: 2, backgroundColor: '#eee', height: '100%' }}
              >
                <div className="flex flex-row" style={{ minHeight: '100vh', height: '100%' }}>
                  {children}
                  {
                    showGrid
                      ? (
                        <div className="flex flex-col flex-1" style={{ margin: '25px 32px 0 25px' }}>
                          <BetHeader />
                          <Grid />
                        </div>
                      )
                      : null
                  }
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </BodyWrapper>
  );
};

export default DashboardLayout;
