import { getEsportes } from './service/oddService'
import { getUserInfo } from './service/userService'

export const setEsportes = (esportes) => {
    return { type: 'filter/esportes', value: esportes }
}

export const setOrdenacao = ({ ordenacao, crescente }) => {
    return { type: 'filter/ordenacao', value: { ordenacao, crescente } }
}

export const setMarcados = (marcados) => {
    return { type: 'filter/marcados', value: marcados }
}

export const setSuperMarcados = (superMarcados) => {
    return { type: 'filter/supermarcados', value: superMarcados }
}

export const setValores = (valores) => {
    return { type: 'filter/valores', value: valores }
}

export const fetchData = (filters) => {
    return dispatch => {
        dispatch(fetchDataBegin())
        getEsportes(filters).then(res => {
            dispatch(fetchFullDataSucess(res))
        }).catch(err => {
            dispatch(fetchDataFaliure(err.message))
        })
    }
}

export const fetchDataBegin = () => {
    return {
        type: 'data/fetchDataBegin',
    }
}

export const fetchDataSucess = (data) => {
    return {
        type: 'data/fetchDataSucess',
        value: { ...data, finalWinOdds: data.finalWinOdds }
    }
}

export const fetchFullDataSucess = (data) => {
    return {
        type: 'data/fetchFullDataSucess',
        value: { ...data, finalWinOdds: data.finalWinOdds }
    }
}

export const fetchDataFaliure = (error) => {
    return {
        type: 'data/fetchDataFaliure',
        value: { error }
    }
}

export const fetchUserInfo = () => {
    return dispatch => {
        dispatch(fetchUserInfoBegin())
        getUserInfo().then(res => {
            dispatch(fetchUserInfoSucess(res.cliente))
        }).catch(err => {
            dispatch(fetchUserInfoFaliure(err.message))
        })
    }
}

export const fetchUserInfoBegin = () => {
    return {
        type: 'user/fetchUserInfoBegin',
    }
}

export const fetchUserInfoSucess = (data) => {
    return {
        type: 'user/fetchUserInfoSucess',
        value: data
    }
}

export const fetchUserInfoFaliure = (error) => {
    return {
        type: 'user/fetchUserInfoFaliure',
        value: { error }
    }
}

export const setCurrentPage = (page) => {
    return {
        type: 'app/currentPage',
        value: page
    }
}


