import api from './api'

export const getEsportes = async (filters) => {
    try {
        const { data: response } = await api.post('/odds', filters)
        return response
    } catch (err) {
        if (err.message === 'Network Error') {
            localStorage.removeItem('isLogged')
        }
    }
}
