import React, { useState } from 'react';

import NavExpanded from '../navExpanded';

import './style.css'

const ZoomPage = () => {
    const [currentZoom, setCurrentZoom] = useState(1.0)
    const SCALE_FACTOR = 0.05

    const root = document.querySelector('#root')

    const zoomOut = () => {
        const newZoom = currentZoom - SCALE_FACTOR
        setCurrentZoom(newZoom)
        root.style = `zoom: ${newZoom}; width: 100%; height: 100%`
    }

    const zoomIn = () => {
        const newZoom = currentZoom + SCALE_FACTOR
        setCurrentZoom(newZoom)
        root.style = `zoom: ${newZoom}; width: 100%; height: 100%`
    }
    return (
        <NavExpanded>
            <div className="zoom">
                <span className="zoom-title">Zoom</span>

                <div className="container">
                    <button className="sub-button" onClick={zoomOut}>
                        <span>-</span>
                    </button>
                    <span>{(100 * currentZoom).toFixed(2)}%</span>
                    <button className="add-button" onClick={zoomIn}>
                        <span>+</span>
                    </button>
                </div>
            </div>
        </NavExpanded>
    )
}

export default ZoomPage;