import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import DashboardLayout from '../components/Layout';
import NavExpanded from '../components/navExpanded';
import BetHeader from '../components/bet-header';
import Button from "../components/button";
import Input from "../components/input";
import SideIcon from '../components/sideIcon'

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as User } from "../assets/user.svg"
import { ReactComponent as Card } from "../assets/credit-card.svg"
import { ReactComponent as Lock } from "../assets/lock.svg"

import { updateUser, getImage, updateImage, removeImage } from '../service/userService'
import { changePassword } from '../service/authService'
import { fetchUserInfo, fetchUserInfoSucess } from '../appActions'
import Plans from '../components/plans';
const SettingsPage = ({ dispatch, userInfo }) => {
  const planName = userInfo?.personalPlanoDTO?.perfilPlanoDTO?.plano?.name || 'NONE'
  let planExpiration = ''

  if (planName !== 'NONE') {
    const dataExpira = userInfo?.personalPlanoDTO?.dataExpira
    planExpiration = dataExpira.split('T')[0].split('-').reverse().join('/')
  }

  const { t } = useTranslation()
  useEffect(() => {
    dispatch(fetchUserInfo())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [displayName, setDisplayName] = useState('');
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [active, setActive] = useState(planName === 'NONE' ? 2 : 1);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  useEffect(() => {
    setDisplayName(userInfo?.nome || '');
    setFullName(userInfo?.nomeCompleto || '');
    setEmailAddress(userInfo?.usuario?.email || '');
    setPhoneNumber(userInfo?.telefone || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])

  const update = async () => {
    try {
      const { data } = await updateUser({
        nome: displayName,
        nomeCompleto: fullName,
        telefone: phoneNumber
      })
      dispatch(fetchUserInfoSucess(data.cliente))
      toast.success(t('dataUpdateSucess'))
    } catch (err) {
      toast.error(err.message)
    }
  }


  const upload = async (image) => {
    try {
      const { data } = await updateImage(image)
      dispatch(fetchUserInfoSucess(data.cliente))
      toast.success(t('imageUploadSuccess'))
    } catch (err) {
      toast.error(err.message)
    }
  }

  const remove = async () => {
    try {
      if (!userInfo?.foto) {
        toast.warning(t('dontHaveImage'))
        return
      }
      const { data } = await removeImage()
      dispatch(fetchUserInfoSucess(data.cliente))
      toast.success(t('imageRemoveSuccess'))
    } catch (err) {
      toast.error(err.message)
    }
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(22),
      height: theme.spacing(22),
    },
  }));

  const classes = useStyles();

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      toast.warning(t('fillAllFields'))
      return
    }

    if (newPassword !== confirmNewPassword) {
      toast.error(t('passDontMatch'))
      return
    }

    if (currentPassword === newPassword) {
      toast.error(t('newPassCantBeTheSame'))
      return
    }

    try {
      await changePassword({ senhaAtual: currentPassword, novaSenha: newPassword })
      toast.success(t('passChangedSuccess'))
      setActive(1)
    } catch (err) {
      toast.error(err.message)
    } finally {
      setCurrentPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
    }
  }

  const getTitle = () => {
    switch (active) {
      case 1:
        return t('editProfile')
      case 2:
        if (planName === 'NONE') {
          return t('choosePlanForFunctionalites')
        } else {
          return `${t('currentPlan')} ${planName} - ${t('expiresIn')} ${planExpiration}`
        }
      case 3:
        return t('password')
      default:
        return t('editProfile')
    }
  }

  return (
    <DashboardLayout showGrid={false}>
      <ToastContainer />
      <NavExpanded>
        <div className="settings-icons-container">

          <SideIcon name="user" active={active === 1} label={t('editProfile')} onclick={() => setActive(1)}>
            <User className="svg" />
          </SideIcon>
          <SideIcon name="plan" active={active === 2} label={t('plan')} onclick={() => setActive(2)}>
            <Card className="svg" />
          </SideIcon>
          <SideIcon name="password" active={active === 3} label={t('password')} onclick={() => setActive(3)}>
            <Lock className="svg" />
          </SideIcon>
        </div>
      </NavExpanded>
      <div className="settings">
        <BetHeader title={t('settings')} />

        <span className="title">{getTitle()}</span>
        <div className="container" style={{ background: active === 2 ? '#eee' : 'white' }}>
          {
            active === 1 ?
              <>
                <div className="fields">
                  <label>{t('displayName')}</label>
                  <Input accent value={displayName} onChange={setDisplayName} />

                  <label>{t('fullName')}</label>
                  <Input disabled value={fullName} onChange={setFullName} />

                  <label>{t('emailAddress')}</label>
                  <Input disabled value={emailAddress} onChange={setEmailAddress} />

                  <label>{t('phoneNumber')}</label>
                  <Input type="mask" mask="+99 (99) 9 9999-9999" accent value={phoneNumber} onChange={setPhoneNumber} />

                  <div className="buttons-container">
                    <Button className="save-btn" title={t('save')} onClick={() => update()} />
                    <Button className="cancel-btn" title={t('cancel')} type="secondary" onClick={() => null} />
                  </div>
                </div>
                <div className="separator" />
                <div className="photo">
                  <Avatar src={getImage(userInfo?.foto)} className={classes.large} />
                  <div className="buttons-container">
                    <Button className="upload-btn color-gray" title={t('upload')} type="upload" onChange={(e) => upload(e.target.files[0])} />
                    <Button className="remove-btn color-gray" title={t('remove')} type="secondary" onClick={() => remove()} />
                  </div>
                </div>
              </>
              :
              active === 2
                ?
                <Plans isLogged />
                :
                <div className="change-pass-container">
                  <label>{t('currentPassword')}</label>
                  <Input type="password" accent value={currentPassword} onChange={setCurrentPassword} />

                  <label>{t('newPassword')}</label>
                  <Input type="password" accent value={newPassword} onChange={setNewPassword} />

                  <label>{t('confirmNewPass')}</label>
                  <Input type="password" accent value={confirmNewPassword} onChange={setConfirmNewPassword} />
                  <Button title="Save" onClick={() => handleChangePassword()} />
                </div>
          }
        </div>
      </div>
    </DashboardLayout >
  )
}

const SettingsPageConnect = connect(store => store)(SettingsPage)
export default SettingsPageConnect;