import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationPTBR from './locales/pt_br/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    pt: {
        code: 'pt-BR',
        translation: translationPTBR
    }
};
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        debug: false,
        detection: {
            order: ['navigator']
        },
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;