import React from 'react';
import InputMask from 'react-input-mask';

import './style.css'

const Input = ({ disabled, accent, white, placeholder, onChange, value, type, mask, className }) => {
  const getClasses = () => {
    let classes = 'input'
    if (disabled)
      classes += ' disabled'
    if (accent)
      classes += ' accent'
    if (white)
      classes += ' white'
    return classes += ` ${className}`
  }
  if (type === 'mask') {
    return <InputMask placeholder={placeholder} className={getClasses()} value={value} onChange={(e) => onChange(e.target.value)} mask={mask} maskChar=" " />;
  }
  return <input className={getClasses()} disabled={disabled} value={value} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} type={type} />
}

export default Input;
