import React from 'react';
import './style.css'

const SideIcon = ({ onclick, children, label, active=false }) => {
    let classes = 'side-icon'
    if(active)
        classes += ' active'
    return (
        <div className={classes} onClick={onclick}>
            {children}
            <span>{label}</span>
        </div>
    )
}

export default SideIcon;
