import React from 'react'
import { ReactComponent as EmptyState } from '../assets/empty_state.svg';
import { ReactComponent as BaseBall } from '../assets/sports/baseball.svg';
import { ReactComponent as Basket } from '../assets/sports/basket.svg';
import { ReactComponent as Criquet } from '../assets/sports/criquet.svg';
import { ReactComponent as Dota2 } from '../assets/sports/dota2.svg';
import { ReactComponent as LeagueOfLegends } from '../assets/sports/league-of-legends.svg'
import { ReactComponent as CounterStrike } from '../assets/sports/counter-strike.svg'
import { ReactComponent as Fifa } from '../assets/sports/fifa.svg'
import { ReactComponent as Starcraft } from '../assets/sports/starcraft.svg'
import { ReactComponent as Futebol } from '../assets/sports/futebol.svg';
import { ReactComponent as HandBall } from '../assets/sports/handball.svg';
import { ReactComponent as Hockey } from '../assets/sports/hockey.svg';
import { ReactComponent as MMA } from '../assets/sports/mma.svg';
import { ReactComponent as Rugby } from '../assets/sports/rugby.svg';
import { ReactComponent as TableTennis } from '../assets/sports/table-tennis.svg';
import { ReactComponent as Tennis } from '../assets/sports/tennis.svg';
import { ReactComponent as VolleyBall } from '../assets/sports/volleyball.svg';


export const getIconBySportName = (sportName) => {
    switch (sportName) {
        case ('Baseball'):
            return <BaseBall className="sport-icon" />
        case ('Basket'):
            return <Basket className="sport-icon" />
        case ('Criquet'):
            return <Criquet className="sport-icon" />
        case ('Dota 2'):
            return <Dota2 className="sport-icon" />
        case ('League Of Legends'):
            return <LeagueOfLegends className="sport-icon" />
        case ('Counter Strike'):
            return <CounterStrike className="sport-icon" />
        case ('Fifa'):
            return <Fifa className="sport-icon" />
        case ('Starcraft 2'):
            return <Starcraft className="sport-icon" />
        case ('Futebol'):
            return <Futebol className="sport-icon" />
        case ('Handball'):
            return <HandBall className="sport-icon" />
        case ('Hockei'):
            return <Hockey className="sport-icon" />
        case ('MMA'):
            return <MMA className="sport-icon" />
        case ('Rugby'):
            return <Rugby className="sport-icon" />
        case ('Tenis Mesa'):
            return <TableTennis className="sport-icon" />
        case ('Tenis'):
            return <Tennis className="sport-icon" />
        case ('Volei'):
            return <VolleyBall className="sport-icon" />
        default:
            return <EmptyState className="sport-icon" />
    }
}