import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';

const baseState = {
    filters: {
        esportesEnum: [],
        ordenacao: "PERCENT",
        crescente: false,
        marcados: ["ALL"],
        superMarcados: [],
        valores: [],
        finalWinOdd: null,
    },
    data: {
        finalWinOdds: [],
        quantidade_odds: 0,
        quantidade_jogos: 0,
    },
    userInfo: {},
    loading: false,
    currentPage: '/',
}

function filtersReducer(state = baseState, action) {
    switch (action.type) {
        case 'filter/esportes':
            return { ...state, filters: { ...state.filters, esportesEnum: action.value } }
        case 'filter/ordenacao':
            const { ordenacao, crescente } = action.value
            return { ...state, filters: { ...state.filters, ordenacao: ordenacao, crescente: crescente } }
        case 'filter/marcados':
            return { ...state, filters: { ...state.filters, marcados: action.value } }
        case 'filter/supermarcados':
            return { ...state, filters: { ...state.filters, superMarcados: action.value } }
        case 'filter/valores':
            return { ...state, filters: { ...state.filters, valores: action.value } }
        case 'data/fetchDataBegin':
            return { ...state, loading: true }
        case 'data/fetchDataSucess':
            return { ...state, data: action.value, loading: false }
        case 'data/fetchFullDataSucess':
            return { ...state, data: action.value, fullData: action.value, loading: false }
        case 'user/fetchUserInfoBegin':
            return { ...state, loading: true }
        case 'user/fetchUserInfoSucess':
            return { ...state, userInfo: action.value, loading: false }
        case 'data/fetchDataFaliure':
            return { ...state, loading: false }
        case 'app/currentPage':
            return { ...state, currentPage: action.value }
        default:
            return state
    }
}

let store = createStore(filtersReducer, applyMiddleware(thunk))

export default store