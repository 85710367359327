import React, {useState} from 'react';
import './style.css';

const CheckBox = ({onChange, onSelect, value, changeBackground, label}) => {
 
  const [background, setBackgournd] = useState('')
  const [clicable] = useState(changeBackground ? 'clicable' : '' )

  const toggleColor = () => {
    if(!changeBackground){
      return
    }
    setBackgournd(background ? '' : 'bg-green')
    onSelect(label)
  }

  return (
    <div className={`checkbox flex items-center ${background}`} >
    <input
      className="mr-1"
      type="checkbox"
      checked={value}
      onChange={
        (e) => onChange(e.target.checked)
      }
    />
    <span className={clicable} onClick={() => toggleColor()}>{label}</span>
    </div>
  )
}

export default CheckBox;
