import React from 'react';
import Button from '../components/button';
import BetHackerLogo from '../assets/BetHackerIcon-side.png'
import PhotoLPSection1 from '../assets/photo-lp-section1.png'
import BethackerHome from '../assets/bethackerHome.png'
import Benefits1 from '../assets/benefits-1.png'
import Benefits2 from '../assets/benefits-2.png'
import { ReactComponent as BetHackerCalculator } from '../assets/surebetcalculator.svg';
import { ReactComponent as Coin } from '../assets/coin.svg';
import { ReactComponent as History } from '../assets/history.svg';
import { ReactComponent as Refresh } from '../assets/refresh.svg';
import { ReactComponent as Trophy } from '../assets/trophy.svg';
import { HashLink as Link } from 'react-router-hash-link';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import InstagramIcon from '@material-ui/icons/Instagram';
import TelegramIcon from '@material-ui/icons/Telegram'
import { useHistory } from 'react-router-dom';

import Plans from '../components/plans'
import { useTranslation } from 'react-i18next';

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../assets/depoimentos/', false, /\.(png|jpe?g|svg)$/));

const LandingPage = () => {
    const history = useHistory()
    const { t } = useTranslation()

    return (
        <div className='landing-page'>

            <div className='landing-page-section landing-page-section-1'>
                <nav className='nav-menu'>
                    <img src={BetHackerLogo} className='bethacker-logo' alt="bethacker logo" />

                    <ul className='nav-list text-white'>
                        <li className='nav-list-item'>
                            <Link smooth to="/#home">{t('home')}</Link>
                        </li>
                        <li className='nav-list-item'>
                            <Link smooth to="/#about">{t('aboutUs')}</Link>
                        </li>
                        <li className='nav-list-item'>
                            <Link smooth to="/#benefits">{t('benefits')}</Link>
                        </li>
                        <li className='nav-list-item'>
                            <Link smooth to="/#pricing">{t('pricing')}</Link>
                        </li>
                        <li className='nav-list-item'>
                            <Link smooth to="/#testmonial">{t('testmonials')}</Link>
                        </li>
                        <li className='nav-list-item'>
                            <Link smooth to="/#contact">{t('contactUs')}</Link>
                        </li>
                        <li className='nav-list-item'><Button className='nav-list-button' title={t('signIn')} onClick={() => history.push('/login')}></Button></li>
                    </ul>
                </nav>

                <div className='presentation'>
                    <div className='presentation-text'>
                        <div className='presentation-text-primary text-white'>
                            <span className="d-block">{t('simpleSmartTool')}</span>
                            <span className='text-bold'>{t('helpHackBets')}</span>
                        </div>
                        <div className='presentation-text-secondary'>
                            <span className='text-gray'>{t('dontBelieveSignUp')}</span>
                        </div>
                        <Button title={t('signUp')} onClick={() => history.push({ pathname: '/login', state: { isSignUp: true } })}></Button>
                    </div>
                    <div className='presentation-images'>
                        <img src={BethackerHome} className='bethacker-home' alt="bethacker home" />
                        <BetHackerCalculator className='bethacker-calculator' />
                        <img src={PhotoLPSection1} className='person' alt="person" />
                    </div>
                </div>
            </div>

            <div id="about" className='landing-page-section landing-page-section-2'>
                <span className='section-text-primary'>{t('redIsDemotivatingFactor')} <span className='text-green'>{t('green')}</span> {t('or')} <span className='text-green'>{t('green')}</span>.
                <div>{t('notBadRight')}</div>
                </span>
                <span className='section-text-secondary'>{t('weRevolutionizedText')}</span>
                <div className='section-tags'>
                    <div className='section-tag-item'>
                        <div className='tag-item-title text-green'>+20</div>
                        <div className='tag-item-subtitle'>{t('marketsAnalyzed')}</div>
                    </div>
                    <div className='section-tag-item'>
                        <div className='tag-item-title text-green'>+15</div>
                        <div className='tag-item-subtitle'>{t('sportsModalities')}</div>
                    </div>
                    <div className='section-tag-item'>
                        <div className='tag-item-title text-green'>+10</div>
                        <div className='tag-item-subtitle'>{t('bookMakers')}</div>
                    </div>
                    <div className='section-tag-item'>
                        <div className='tag-item-title text-green'>+02</div>
                        <div className='tag-item-subtitle'>{t('differentCurrencies')}</div>
                    </div>
                </div>
            </div>

            <div id="benefits" className='landing-page-section landing-page-section-3'>
                <div className='benefits-info'>
                    <div className='benefits-text'>
                        <span className='section-text-primary'>{t('ourStrategy')}</span>
                        <span className='section-text-secondary'>
                            {t('strategyDescriptionText')}
                        </span>
                    </div>
                    <div className='benfits-images'>
                        <img src={Benefits1} className='benefits-img-1' alt="benefits img 1" />
                        <img src={Benefits2} className='benefits-img-2' alt="benefits img 2" />
                    </div>
                </div>
                <div className='section-tags'>
                    <div className='section-tag-item'>
                        <Coin className="tag-item-icon" style={{ backgroundColor: '#72D46325' }} />
                        <div className='tag-item-title'>{t('upTo20percent')}</div>
                        <div className='tag-item-subtitle'></div>
                    </div>
                    <div className='section-tag-item'>
                        <Trophy className="tag-item-icon" style={{ backgroundColor: '#F8BC2925' }} />
                        <div className='tag-item-title'>{t('hundreadsOpportunities')}</div>
                        <div className='tag-item-subtitle'></div>
                    </div>
                    <div className='section-tag-item'>
                        <Refresh className="tag-item-icon" style={{ backgroundColor: '#2296F325' }} />
                        <div className='tag-item-title'>{t('liquidityOdds')}</div>
                        <div className='tag-item-subtitle'></div>
                    </div>
                    <div className='section-tag-item'>
                        <History className="tag-item-icon" style={{ backgroundColor: '#EB522F25' }} />
                        <div className='tag-item-title'>{t('avaliable24Hours')}</div>
                        <div className='tag-item-subtitle'></div>
                    </div>
                </div>
            </div>

            <div id="pricing" className='landing-page-section landing-page-section-4'>
                <div className='pricing-info'>
                    <div className='pricing-text'>
                        <span className='section-text-primary'>{t('chooseThePlan')}</span>
                        <span className='section-text-secondary'>
                        </span>
                    </div>
                    <Plans />
                </div>
            </div>
            <div id="testmonial" className='landing-page-section landing-page-section-5'>
                <div className='testmonial-text-title'>{t('testmonials')}</div>
                <Carousel
                    showArrows={false}
                    autoPlay
                    infiniteLoop
                    showStatus={false}>
                    {
                        images.map((img, index) => (
                            <img
                                key={index}
                                alt={`depoimento-${index}`}
                                style={{ maxHeight: '50vh', objectFit: 'contain', padding: '10px 0' }}
                                src={img.default} />)
                        )
                    }
                </Carousel>
            </div>
            <div id="contact" className='landing-page-section landing-page-section-6'>
                <div className='flex align-center justify-between'>
                    <img src={BetHackerLogo} alt='bethacker logo' />
                    <div className='flex col'>
                        <div className='text-green'>{t('office')}</div>
                        <div className='text-white'>
                            <div>BetHacker Apostas Esportivas LTDA</div>
                            <div>CNPJ: 10.123.456/0001-99</div>
                        </div>
                    </div>

                    <div className='flex col'>
                        <div className='text-green'>{t('suport')}</div>
                        <div className='text-white'>
                            <div>+55 85 9 1234-5678</div>
                            <div>suport@bethacker.com</div>
                        </div>
                    </div>

                    <div className='flex col'>
                        <div className='text-green'>{t('sales')}</div>
                        <div className='text-white'>
                            <div>+55 85 9 8765-4321</div>
                            <div> sales@bethacker.com</div>
                        </div>
                    </div>

                    <div className='flex col'>
                        <div className='text-green'>{t('social')}</div>
                        <div className='flex justify-between'>
                            <div className='text-white'>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/regishacks/">
                                    <TelegramIcon />
                                </a>
                            </div>
                            <div className='text-white'>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/regishacks/">
                                    <InstagramIcon />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <span className='policy-and-terms text-white'>
                    <Link to="#" className='link-style'>{t('privacyPolicy')}</Link> {t('and')} <Link to="#" className='link-style'>{t('termsOfService')}</Link>
                </span>
            </div>
        </div >
    );
}

export default LandingPage;