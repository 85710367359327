import React from 'react';
import './style.css';
import CircularProgress from '@material-ui/core/CircularProgress';

const Button = ({ title, onClick, onChange, className, loading = false, type = "primary" }, rest) => {
  if (type === "upload")
    return (
      <label className={`button primary uploader ${className}`}>
        <span>{title}</span>
        <input type="file" onChange={onChange} />
      </label>)

  return (
    <button
      {...rest}
      onClick={onClick}
      className={`button ${type} ${className}`}
    >
      {
        loading
          ? <CircularProgress size={20} style={{ color: type === 'primary' ? '#fff' : '#04D361' }} />
          : title
      }
    </button>
  )
}

export default Button;
