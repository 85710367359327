import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux'
import { fetchData, setOrdenacao } from '../../appActions'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Calculator from '../calculator'
import ReportButton from '../report'

import { formatDate, getAge } from "../../utils/date";
import { getIconBySportName } from '../../utils/sportIcon'
import { ReactComponent as EmptyState } from '../../assets/empty_state.svg';

import AutoSizer from 'react-virtualized-auto-sizer';
import memoize from "memoize-one";
import { FixedSizeList as List } from "react-window";

import './style.css'
import useInterval from '../../hooks/useInterval';
import { ToastContainer } from 'react-toastify';

import RefreshIcon from '@material-ui/icons/Refresh';
import { Fab } from '@material-ui/core';
import { checkUser, logout } from '../../service/authService';

const ROW_SIZE = 115;

const TableHeaderColumns = ({ columns, filters, sortFunction }) => {
  return (
    <TableRow component="div" style={{ display: 'flex' }} >
      {columns.map((column, index) => {
        return (
          <TableCell component="div" variant="head" scope="col" style={{ flex: 1 }} className={column.sortable ? 'sortable-cell' : null} onClick={() => column.sortable && sortFunction(column.sortValue)} key={index}>
            {column.label}
            {
              column.sortable ?
                filters.crescente
                  ? <ArrowDropDownIcon className={column.sortValue === filters.ordenacao ? 'sort-icon--active' : null} />
                  : <ArrowDropUpIcon className={column.sortValue === filters.ordenacao ? 'sort-icon--active' : null} />
                : null
            }
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const Row = ({ index, style, data: { items, selectedMatch, setSelectedMatch } }) => {
  const match = items[index];

  return (
    <TableRow component="div" key={index} className="grid-row" style={style} onClick={() => setSelectedMatch(match === selectedMatch ? null : match)}>
      <TableCell component="div" className="grid-row__percent" >
        {match.odd_a.porcentagem.toFixed(2)} %
      </TableCell>
      <TableCell component="div" className="grid-row__sport">
        {getIconBySportName(match.esporte)}
        <span>{match.esporte}</span>
      </TableCell>
      <TableCell component="div" className="grid-row__age">
        {getAge(match.odd_a.age, match.odd_b.age)}
      </TableCell>
      <TableCell component="div" className="grid-row__liga">
        <span title={match.liga}>{match.liga}</span>
      </TableCell>
      <TableCell component="div" className="odd grid-row__bookmark">
        <div className="odd-a">
          <a target="_blank" rel="noreferrer" href={match.odd_a.link}>
            {match.odd_a.nameBookmaker}
          </a>
        </div>
        <hr />
        <div className="odd-b">
          <a href={match.odd_b.link}>
            {match.odd_b.nameBookmaker}
          </a>
        </div>
      </TableCell>
      <TableCell component="div" className="odd grid-row__beginning-time">
        <div className="odd-a">
          <span className="date">{formatDate(match.date)}</span>
          <span className="time">{match.time}</span>
        </div>
        <hr />
        <div className="odd-b">
          <span className="date">{formatDate(match.date)}</span>
          <span className="time">{match.time}</span>
        </div>
      </TableCell>
      <TableCell component="div" className="odd grid-row__event">
        <div className="odd-a">
          <span className="title">{match.localteam}</span>
          <span className="subtitle">{match.name}</span>
        </div>
        <hr />
        <div className="odd-b">
          <span className="title">{match.awayteam}</span>
          <span className="subtitle">{match.name}</span>
        </div>
      </TableCell>
      <TableCell component="div" className="odd grid-row__market">
        <div title={match.odd_a.type} className="odd-a">
          {match.odd_a.type}
        </div>
        <hr />
        <div title={match.odd_b.type} className="odd-b">
          {match.odd_b.type}
        </div>
      </TableCell>
      <TableCell component="div" className="odd grid-row__name">
        <div className="odd-a">
          {match.odd_a.pontos ? `${match.odd_a.name}: ${match.odd_a.pontos}` : match.odd_a.name}
        </div>
        <hr />
        <div className="odd-b">
          {match.odd_b.pontos ? `${match.odd_b.name}: ${match.odd_b.pontos}` : match.odd_b.name}
        </div>
      </TableCell>
      <TableCell component="div" className="odd grid-row__quota">
        <div className="odd-a">
          {match.odd_a.value}
        </div>
        <hr />
        <div className="odd-b">
          {match.odd_b.value}
        </div>
      </TableCell>
      <TableCell component="div" className={_.isEqual(selectedMatch, match) ? 'odd grid-row__last--active' : 'odd grid-row__last'} >
        <ReportButton value={{ ...match, odd_b: null }} title="Click to report a problem in first odd" />
        <ReportButton value={{ ...match, odd_a: null }} title="Click to report a problem in second odd" />
      </TableCell>
    </TableRow>
  );
};

const createItemData = memoize((columns, data, loading, selectedMatch, setSelectedMatch) => ({
  columns,
  items: data.finalWinOdds,
  loading,
  selectedMatch,
  setSelectedMatch
}));

const ReactWindowTable = ({ data, columns, sortFunction, filters, loading, translate, selectedMatch, setSelectedMatch, getData }) => {

  const itemData = createItemData(columns, data, loading, selectedMatch, setSelectedMatch);
  return (
    <div className='grid-container'>
      {
        !loading
          ?
          <div className="top-info">
            <div>
              <span className="opportunities">{translate('opportunities')}: <span className="value">{data.quantidade_odds}</span></span>
              <span className="matches">{translate('matches')}: <span className="value">{data.quantidade_jogos}</span></span>
            </div>
          </div>
          :
          null
      }
      {
        selectedMatch
          ?
          <Calculator match={selectedMatch} onBack={() => setSelectedMatch(null)} />
          :
          null
      }
      <Table component="div" style={{ height: !selectedMatch ? "85%" : "calc(90% - 200px)" }} stickyHeader aria-label="sticky table">
        <TableHead component="div" className="grid-header no-wrap" >
          <TableHeaderColumns columns={columns} sortFunction={sortFunction} filters={filters} />
        </TableHead>

        <TableBody component="div" className="grid-body">
          {
            loading
              ?
              <TableRow component="div">
                <TableCell component="div" colSpan={columns.length} style={{ borderBottom: 'none' }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress style={{ color: '#04D361' }} />
                  </div>
                </TableCell>
              </TableRow>
              :
              data.quantidade_odds ?
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      height={height}
                      width={width}
                      itemCount={data.quantidade_odds}
                      itemSize={ROW_SIZE}
                      itemData={itemData}
                    >
                      {Row}
                    </List>
                  )}
                </AutoSizer>
                : <TableRow component="div">
                  <TableCell component="div" colSpan={columns.length} style={{ borderBottom: 'none' }}>
                    <EmptyState className="empty-state" />
                    <p className="empty-state-text">{translate('noItemsFound')}</p>
                  </TableCell>
                </TableRow>
          }
        </TableBody>
      </Table>
      {
        !loading && itemData.items.length ?
          <Fab style={{ marginLeft: "auto", boxShadow: "none", background: "#04D361", marginTop: "auto" }} color="primary" aria-label="add" onClick={() => getData()}>
            <RefreshIcon />
          </Fab>
          : null
      }
    </div >
  );
};

const Grid = () => {
  const { t } = useTranslation()
  const { filters, loading, data, finalWinOdd } = useSelector(state => state)
  const [selectedMatch, setSelectedMatch] = useState(finalWinOdd)
  const dispatch = useDispatch()
  const REFRESH_TIME_SECONDS = 1000 * 60 * 2
  const CHECK_USER_TIME_SECONDS = 1000 * 20

  const getData = () => dispatch(fetchData({ ...filters, finalWinOdd: selectedMatch }))

  useInterval(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [REFRESH_TIME_SECONDS])

  useInterval(() => {

    const verify = async () => {
      const { data } = await checkUser()

      if (!data) {
        logout()
      }
    }

    verify()
  }, [CHECK_USER_TIME_SECONDS])

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatch, filters])

  const columns = [
    {
      label: t('percent'),
      sortable: true,
      sortValue: 'PERCENT'
    },
    {
      label: t('sport'),
    },
    {
      label: t('age'),
      sortable: 'true',
      sortValue: 'AGE'
    },
    {
      label: t('liga'),
    },
    {
      label: t('bookMaker'),
    },
    {
      label: t('beginning'),
      sortable: true,
      sortValue: 'DATE_TIME'
    },
    {
      label: t('event'),
    },
    {
      label: t('market'),
    },
    {
      label: t('name'),
    },
    {
      label: t('quota'),
    }]

  const sortFunction = (value) => {
    dispatch(setOrdenacao({ crescente: !filters.crescente, ordenacao: value }))
  }

  return (
    <>
      <ToastContainer />
      <ReactWindowTable
        columns={columns}
        data={data}
        sortFunction={sortFunction}
        filters={filters}
        loading={loading}
        translate={t}
        selectedMatch={selectedMatch}
        setSelectedMatch={setSelectedMatch}
        getData={getData}
      />
    </>
  );
};

export default Grid;
