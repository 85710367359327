import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import Avatar from '@material-ui/core/Avatar';

import { getImage } from "../../service/userService"
import { connect } from 'react-redux'
import { fetchUserInfo } from '../../appActions'
import { IconButton, Tooltip } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import { logout } from '../../service/authService'

import './style.css';

const BetHeader = ({ dispatch, userInfo, title = 'BET HACKER' }) => {
    const { t } = useTranslation()
    useEffect(() => {
        if (_.isEmpty(userInfo)) {
            dispatch(fetchUserInfo())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="bet-header">
            <div className="content">
                <span className="title">{title}</span>
                <div className="left">
                    <div className="profile">
                        <Avatar src={getImage(userInfo?.foto)} />
                        <div className="profile-info">
                            <div className="username">{userInfo?.nome}</div>
                        </div>
                        <Tooltip title={t('logout')} >
                            <IconButton onClick={() => logout()} className="btn-logout" size="small">
                                <PowerSettingsNewIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <hr />
        </div >
    )
}

const BetHeaderConnect = connect(store => store)(BetHeader)
export default BetHeaderConnect;
