import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { doReport } from '../../service/userService';


const useStyles = makeStyles(() => ({
    buttonContainer: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    button: {
        color: '#fc9003',
        padding: "0 10px",
        fontSize: "14px",
    },
}));

export default function ReportButton({ value, title }) {
    const classes = useStyles();

    const sendReport = async (e) => {
        e.stopPropagation()
        try {
            await doReport(value)
            toast.success('Report enviado com sucesso!')
        } catch (err) {
            toast.error('Erro ao enviar report!')
        }
    }

    return (
        <div className={classes.buttonContainer} onClick={sendReport} title={title}>
            <div className={classes.button} >
                <ReportProblemIcon />
            </div>
        </div>
    );
}
