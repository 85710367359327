import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './style.css'

const NavExpanded = ({ children }) => {
    const loading = useSelector(state => state.loading)
    const location = useLocation()

    const getClasses = () => {
        const classes = ['content']
        if (loading && location.pathname !== '/settings')
            classes.push('content-loading')
        return classes.join(' ')
    }
    return (
        <div className="nav-expanded">
            <div className={getClasses()}>
                <span className="title">BET HACKER</span>
                <div className="wrapper">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default NavExpanded;
