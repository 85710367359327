import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { setOrdenacao } from '../../appActions'
import useDidMountEffect from '../../hooks/useDidMountEffect'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import NavExpanded from '../navExpanded';

import './style.css'

const ProjectsPage = () => {
    const { t } = useTranslation()
    const filters = useSelector(state => state.filters)
    const dispatch = useDispatch()

    const [order, setOrder] = useState(filters.ordenacao)
    const [crescente, setCrescente] = useState(filters.crescente)

    useDidMountEffect(() => {
        dispatch(setOrdenacao({ crescente, ordenacao: order }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, crescente])

    const GreenRadio = withStyles({
        root: {
            '&$checked': {
                color: '#04D361',
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);


    return (
        <NavExpanded>
            <div className="projects">
                <RadioGroup className="sort" aria-label="sort-by" name="sort-by">
                    <span className="projects-title">{t('sortedBy')}</span>
                    <FormControlLabel value="percent" control={<GreenRadio onClick={() => setOrder('PERCENT')} checked={filters.ordenacao === 'PERCENT'} />} label={t('percent')} />
                    <FormControlLabel value="age" control={<GreenRadio />} onClick={() => setOrder('AGE')} checked={filters.ordenacao === 'AGE'} label={t('age')} />
                    <FormControlLabel value="beginning-time" control={<GreenRadio />} onClick={() => setOrder('DATE_TIME')} checked={filters.ordenacao === 'DATE_TIME'} label={t('beginning')} />
                </RadioGroup>

                <RadioGroup aria-label="order" name="order">
                    <span className="projects-title order">{t('order')}</span>
                    <FormControlLabel value="asc" control={<GreenRadio checked={!filters.crescente} />} onClick={() => setCrescente(false)} label={t('decreasing')} />
                    <FormControlLabel value="desc" control={<GreenRadio checked={filters.crescente} />} onClick={() => setCrescente(true)} label={t('crescent')} />
                </RadioGroup>
            </div>
        </NavExpanded>
    )
}

export default ProjectsPage;