import React, { useState, useEffect } from 'react';
import { allPlanos } from '../../service/planosService';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import './style.css'
import { useTranslation } from 'react-i18next';

const Plans = ({ isLogged }) => {
    const { t } = useTranslation()
    const totalCasas = 20;

    const [plansArray, setPlansArray] = useState([])
    const [link, setLink] = useState('')

    useEffect(() => {
        let mounted = true;

        async function fetchPlanos() {
            if (mounted) {
                const { data } = await allPlanos()
                setPlansArray(data.planos)
                setLink(data.link)
            }
        }

        fetchPlanos()

        return () => mounted = false
    }, [])


    return (
        <div className="plans-list">
            {
                plansArray.map((plan, index) =>
                    <div key={index} className="plan-card">
                        <div className='plan-card-header'>
                            <div className='plan-card-name' >{plan.name}</div>
                            <div className='plan-card-price' >
                                <span>R$ {plan.preco}</span>
                            </div>
                            <div className='plan-card-offer' >
                                <span className='currency-value'> {t('only')} R$ {(plan.preco / plan.meses).toFixed(2)} / {t('month')}</span>
                            </div>
                        </div>
                        <div className='plan-card-body'>
                            <div className='card-incluse'>
                                <span className='card-incluse-title'>
                                    <CheckIcon className="icon-active" />  {plan.meses} {plan.meses > 1 ? t('months') : t('month')} {t('ofAccess')}
                                </span>
                            </div>

                            <div className='card-incluse'>
                                <span className='card-incluse-title'>
                                    <CheckIcon className="icon-active" />  {`${t('until')} ${plan.porcentagemLucro}% ${t('profit')}`} {t('byOpportunity')}
                                </span>
                            </div>

                            <div className='card-incluse'>
                                <span className='card-incluse-title'>
                                    <CheckIcon className="icon-active" />  {t('until')} {totalCasas - plan.bookmakers.length} {t('houses')}
                                </span>
                            </div>

                            <div className="card-incluse">
                                <span className='card-incluse-title' style={{ color: plan.bookmakers.includes('BET365') ? '#aaa' : '' }}>
                                    {
                                        plan.bookmakers.includes('BET365')
                                            ? <CloseIcon />
                                            : <CheckIcon className="icon-active" />
                                    }    {plan.bookmakers.includes('BET365') ? t('nothaveBET365') : t('haveBET365')}
                                </span>
                            </div>

                            <div className='card-incluse'>
                                <span className='card-incluse-title'>
                                    <CheckIcon className="icon-active" />  {t('hundreds')} {t('ofDailyOpportunities')}
                                </span>
                            </div>

                            <div className='card-incluse'>
                                <span className='card-incluse-title'>
                                    <CheckIcon className="icon-active" />  {t('houseFilter')}
                                </span>
                            </div>
                        </div>

                        <div className="card-footer">
                            {
                                <a href={isLogged ? `${link}${plan.key}` : '/#login'} target={isLogged ? '_blank' : ''} rel="noreferrer" className="card-button">{t('select')}
                                    <ArrowRightAltIcon className="card-button-icon" />
                                </a>
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Plans;
