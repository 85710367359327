import axios from 'axios'
import { getCurrentSession } from './authService'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})

api.interceptors.request.use(async (req) => {
    if (req.url === '/clientes' && req.method === 'post')
        return req
    if (['/oauth/token', '/usuarios/autenticacao', '/usuarios/esqueceu_senha', '/usuarios/atualizar_senha', '/planos/all'].includes(req.url))
        return req
    try {
        let session = await getCurrentSession()
        req.headers.Authorization = `Bearer ${session.access_token}`
        return req
    } catch (err) {
        localStorage.removeItem('isLogged')
        window.location.href = '/#/'
        window.location.reload()
    }
})

api.interceptors.response.use(undefined, async (err) => {
    const res = err.response
    if (res && res.status === 401) {
        localStorage.removeItem('isLogged')
        window.location.href = '/#/'
    }
    else if (res && res.status === 500) {
        throw new Error({ message: 'Internal server error.' })
    }
    else if (res && res.status === 400) {
        if (res.data.error_description === 'Bad credentials') {
            throw new Error('Wrong email or password.')
        } else if (res.data.error === 'invalid_grant') {
            throw new Error('User not yet confirmed.')
        }
        else if (res.data.error === 'Senha atual está inválida') {
            throw new Error('Current password is incorrect.')
        }
    }
    return Promise.reject(err)
})

export default api