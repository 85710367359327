import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Button from "../components/button";
import Input from "../components/input";

import leftBg from "../assets/left.jpg"
import rightBg from "../assets/right.jpg"

import { register, login, forgotPassService } from "../service/authService"
import { getUserInfo } from '../service/userService'

const SignIn = ({ location }) => {
  const isSignUp = location.state?.isSignUp
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [signIn, setSignIn] = useState(!isSignUp);
  const [signUp, setSignUp] = useState(isSignUp);
  const [forgotPass, setForgotPass] = useState(false);
  const [fullName, setfullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const history = useHistory();


  const clearFields = () => {
    setfullName('')
    setEmail('')
    setPhone('')
    setPassword('')
    setConfirmPassword('')
  }

  const doSignIn = async () => {
    try {
      setIsLoading(true)
      await login({ username: email, password })

      const { cliente } = await getUserInfo()
      const plano = cliente?.personalPlanoDTO?.perfilPlanoDTO.plano
      if (plano?.name === 'NONE') {
        history.push('/settings')
      } else {
        history.push('/')
        window.location.reload()
      }
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const doSignUp = async () => {
    try {
      setIsLoading(true)
      await register({
        nome: fullName,
        nomeCompleto: fullName,
        telefone: phone,
        usuario: {
          email,
          password,
        }
      })
      showSignIn()
      toast.success(t('successCheckEmail'))
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const doForgotPass = async () => {
    try {
      setIsLoading(true)
      await forgotPassService(email)
      toast.success(t('successCheckEmail'))
      showSignIn()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const showSignIn = () => {
    setSignIn(true)
    setSignUp(false)
    setForgotPass(false)
    clearFields()
  }

  const showSignUp = () => {
    setSignIn(false)
    setForgotPass(false)
    setSignUp(true)
    clearFields()
  }

  const showForgotPass = () => {
    setSignIn(false)
    setSignUp(false)
    setForgotPass(true)
    clearFields()
  }

  const handleEnter = (evt) => {
    if (evt.key === 'Enter') {
      doSignIn()
    }
  }

  return (
    <section className="sign-in">
      <ToastContainer />
      <div className="page-bg">
        <img className="left-bg" src={leftBg} alt="left" />
        <img className="right-bg" src={rightBg} alt="right" />
      </div>

      <div className="content">
        <div className={`${!signUp ? 'to-left-init-left  ' : 'to-right-init-left '} first-side`}>
          <div className="presentation" style={{ display: `${signUp ? 'none' : 'block'}` }}>
            <h2 style={{ marginBottom: "35px" }}>{t('hello')}</h2>

            <p>{t('signUpDescription')}</p>

            <div style={{ marginTop: "35px" }}>
              <Button title={t('signUp')} type="light" onClick={() => showSignUp()} />
            </div>
          </div>

          <div className="presentation" style={{ display: `${!signUp ? 'none' : 'block'}` }}>
            <h2 style={{ marginBottom: "35px" }}>{t('welcome')}!</h2>

            <p>{t('signInDescription')}</p>

            <div style={{ marginTop: "35px" }}>
              <Button title={t('signIn')} type="light" onClick={() => showSignIn()} />
            </div>
          </div>
        </div>

        <div className={`${signUp ? 'to-right-init-right' : 'to-left-init-right'} second-side`}>
          <div className="form" style={{ display: `${signIn ? 'flex' : 'none'}` }} onKeyDown={(e) => handleEnter(e)}>
            <div style={{ marginBottom: "35px" }}>
              <h3>{t('welcome')}</h3>
              <h2>{t('signInTitle')}</h2>
            </div>

            <Input placeholder="Email" type="email" value={email} onChange={setEmail} />
            <Input placeholder={t('password')} type="password" value={password} onChange={setPassword} />

            <div className="wrap" style={{ justifyContent: 'center' }}>
              <div className="group">
                <span onClick={() => showForgotPass()}>{t('forgotPassword')}?</span>
              </div>
            </div>

            <Button loading={isLoading} title={t('login')} onClick={doSignIn} />

            <div style={{ marginTop: "25px", fontSize: ".8rem" }}>
              <span> {t('dontHaveAccount')} </span>

              <span onClick={() => showSignUp()} style={{ color: "#04D361", cursor: "pointer" }}>
                {t('signUp')}
              </span>
            </div>
          </div>

          <div className="form" style={{ display: `${!signUp ? 'none' : 'flex'}` }}>
            <div style={{ marginBottom: "35px" }}>
              <h2>{t('createAccount')}</h2>
            </div>

            <Input placeholder={t('name')} type="text" value={fullName} onChange={setfullName} />
            <Input placeholder="Email" type="email" value={email} onChange={setEmail} />
            <Input placeholder={t('phone')} type="mask" mask="+99 (99) 9 9999-9999" value={phone} onChange={setPhone} />
            <Input placeholder={t('password')} type="password" value={password} onChange={setPassword} />
            <Input placeholder={t('confPass')} type="password" value={confirmPassword} onChange={setConfirmPassword} />
            <Button title={t('register')} loading={isLoading} onClick={doSignUp} />

            <div style={{ marginTop: "25px", fontSize: ".8rem" }}>
              <span> {t('haveAccount')} </span>

              <span onClick={() => showSignIn()} style={{ color: "#04D361", cursor: "pointer" }}>
                {t('signIn')}
              </span>
            </div>

          </div>

          <div className="form" style={{ display: `${!forgotPass ? 'none' : 'flex'}` }}>
            <div style={{ marginBottom: "35px" }}>
              <h2>{t('forgotPassword')}</h2>
            </div>

            <Input placeholder="Email" type="email" value={email} onChange={setEmail} />
            <Button title={t('sendRecovery')} loading={isLoading} onClick={doForgotPass} />

            <div style={{ marginTop: "25px", fontSize: ".8rem" }}>
              <span onClick={() => showSignIn()} style={{ color: "#04D361", cursor: "pointer" }}>
                {t('backToSignIn')}
              </span>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default SignIn;
